<template>
  <div class="header-container base-color clear" :style="{'padding': pad}">
    <div class="side-ball side-ball-L base-color"></div>
    <div class="side-ball side-ball-R base-color"></div>
    <div class="side-dot  side-dot-L" v-if="showDot"></div>
    <div class="side-dot  side-dot-R" v-if="showDot"></div>
    <!-- customize child components here! -->
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    props: { pad: String, showDot: Boolean, },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-container {
    position: relative;
    /*height: 40vh;*/
    width: 100vw;
    z-index: 10;
  }

  .side-ball {
    position: absolute;
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
  }

  .side-ball-L {
    bottom: -5vh;
    left: -5vh;
  }

  .side-ball-R {
    bottom: -5vh;
    right: -5vh;
  }

  .side-dot {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3E3E3E;
    opacity: .3;
  }

  .side-dot-L {
    top: 30px;
    left: 30px;
  }

  .side-dot-R {
    top: 30px;
    right: 30px;
  }
</style>
