<template>
  <div class="page-container clear">
    <Header pad="40px 0" :showDot="true" class="aaa">
      <div class="header-content V-alignment">
        <el-image :src="q.projectImg" fit="fill" class="q-img"></el-image>
        <XText c="#FFF" s="1.2rem" fw="900" mg="24px 0 0 0">{{q.projectName}}</XText>
        <XText c="#FFF" s=".85rem" fw="900" mg="8px 0 0 0">| 国际通用标准版 |</XText>
        <div class="q-info H-alignment" style="justify-content: space-around;">
          <div class="V-alignment">
            <XText bkg="rgba(0, 0, 0, .3" c="#FFF" s=".9rem" pad="6px 10px" br="10px" fw="900">题目数量</XText>
            <XText c="yellow" s=".9rem" fw="900" mg="10px 0 0 0">{{q.questionNum}} 道</XText>
          </div>
          <div class="V-alignment">
            <XText bkg="rgba(0, 0, 0, .3" c="#FFF" s=".9rem" pad="6px 10px" br="10px" fw="900">适用年龄</XText>
            <XText c="yellow" s=".9rem" fw="900" mg="10px 0 0 0">{{q.minAge}} - {{ q.maxAge }}</XText>
          </div>
          <div class="V-alignment">
            <XText bkg="rgba(0, 0, 0, .3" c="#FFF" s=".9rem" pad="6px 10px" br="10px" fw="900">适用性别</XText>
            <XText c="yellow" s=".9rem" fw="900" mg="10px 0 0 0">{{q.applySex}}</XText>
          </div>
        </div>
      </div>
    </Header>
    <!-- main -->
    <div class="main-content V-alignment">
      <XText c="#3E3E3E" s=".9rem" fw="900" bkg="#F2F2F2" pad="6px 8px" br="10px">测评简介</XText>
      <XText c="#3E3E3E" s=".8rem" ta="left" lh="1.8" mg="10px 0 20px 0">
        <!-- {{q.projectDesc.substr(0, 120)}} ... -->
        <!-- 0项症状自评量表（SymptomChecklist90，SCL-90），是世界上最著名的心理健康测试量表之一，是当前使用最为广泛的精神障碍和心理疾病门诊检查量表，测试报告将从多维度来了解自己的心理健康程度 -->
        {{q.projectDesc}}
      </XText>
      <XText c="#3E3E3E" s=".9rem" fw="900" bkg="#F2F2F2" pad="6px 8px" br="10px">测评须知</XText>
      <XText c="#3E3E3E" s=".8rem" ta="left" lh="1.8" mg="10px 0 0 0">
        测评过程中列出了有些人可能会有的问题，请仔细地阅读每一条，然后根据最近一星期以内下述情况影响你的实际感觉 <span style="font-weight: bolder; color:#F56C6C">(1.没有  2.很轻  3.中等  4.偏重  5.严重)</span>，在五个答案里选择一个最适合你的答案， <span style="font-weight: bolder; color:#409EFF">现在开始吧！</span>
      </XText>

      <el-button type="primary" round @click="go">开始测试<i class="iconfont icon-hot1"></i></el-button>

    </div>
  </div>
</template>

<script>

  // @api
  import { getQuestionInfo } from 'api/q'

  // @components
  import Header from 'components/common/Header'
  import XText  from 'components/common/XText'

  export default {
    name: 'QDetail',
    props: {},
    components: {
      Header, XText
    },
    async mounted(e) {
      const res = await getQuestionInfo(this.$route.query.pid);
      this.q = res.resData
    },
    methods: {
      async go() {
        this.$router.push({ name: 'QItem', query: { pid: this.$route.query.pid }});
      }
    },
    computed: {
    },
    data() {
      return {
        q: {  },
        scene: {},
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-container {
    background: #F2F2F2;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .header-content {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  .main-content {
    position: relative;
    width: calc(100vw - 40px);
    /*height: 300px;*/
    margin-left: 20px;
    background: #FFF;
    z-index: 100;
    margin-top: -20px;
    border-radius: 10px;
    align-items: flex-start;
    padding: 16px;
  }

  .q-img {
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    border: 5px solid white;
  }

  .q-info {
    margin-top: 24px;
    width: calc(100% - 60px);
  }

  .el-button {
    position: relative;
    width: 100%;
    margin-top: 30px;
    font-size: 1rem;
    font-weight: 900;
  }

  .el-button i {
    position: absolute;
    right: 0;
    top: -28px;
    color: #F56C6C;
    font-size: 3rem;
  }
</style>