import ccreq from '../ccnet/ccreq'

// 0
export function queryDecodedScene(h5_code){ return ccreq.post(`/api/h5/cgi-bin/h5-link/query`, { h5_code }) }
// 1
export function getQuestionInfo(pid){ return ccreq.get(`/api/projectType/getProjectById/${pid}`, {}) }
// 2
export function getQuestionItems(pid, page, size){ return ccreq.get(`/api/project/question/questionList/${pid}?page=${page}&size=${size}`, {}) }
// 3
export function makeH5Order(params){ return ccreq.post(`/api/h5/cgi-bin/h5-link/makeOrder`, params) }
// 4
export function saveAnwser(uid, params){ return ccreq.post(`/api/user/answer/h5save/${uid}`, params) }
// 5
export function submitAnswer(ordId){ return ccreq.post(`/api/h5/cgi-bin/h5-link/submitOrder/${ordId}`, {} ) }
// 6
export function generatorReport(uid, params){ return ccreq.post(`/api/user/answer/generatorH5Report/${uid}`, params ) }
// 7
export function payH5Order(orderId){ return ccreq.post(`/api/h5/wechatPay`, { orderId } ) }